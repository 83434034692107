import { Flex, Skeleton, Stack } from '@chakra-ui/react'
import { format } from 'date-fns'
import { useContext } from 'react'
import { AiOutlineCloseCircle } from 'react-icons/ai'
import { useHistory } from 'react-router-dom'
import { GlobalContext } from '../..'
import profilePicture from '../../assets/svgs/avtar-rect.svg'

const ShowSearchMessage = ({
  isSearchLoading,
  setIsSearch,
  searchResult,
  userSearchResult,
  handleClearSearch,
  threadsSorted,
  onReceiverSelected,
  setScrollMessageId,
}) => {
  const { userInfo } = useContext(GlobalContext)
  const history = useHistory()

  const handleClick = (selectedThread, isMessageClicked = false) => {
    if (selectedThread.length) {
      const messageThread = selectedThread[0]

      let contact
      if (userInfo.id === messageThread?.receiver?._id) {
        contact = messageThread?.sender
      } else {
        contact = messageThread?.receiver
      }
      history.replace({})
      localStorage.setItem('thread', JSON.stringify(messageThread))
      onReceiverSelected(messageThread, contact, isMessageClicked)
      setIsSearch(false)
      handleClearSearch()
    }
  }

  const handleUserClick = (user) => {
    const selectedThread = threadsSorted.filter(
      (t) => t.receiverId === user.id || t.senderId === user.id
    )

    handleClick(selectedThread)
  }

  const handleMessageClick = (message) => {
    const selectedThread = threadsSorted.filter((t) => t._id === message.threadId)

    handleClick(selectedThread, true)
    setScrollMessageId(message._id)
  }

  return (
    <div>
      <div className="grid justify-end mr-4">
        <button
          onClick={() => {
            setIsSearch(false)
            handleClearSearch()
          }}
          className="block rounded-full hover:bg-gray-700 bg-gray-800 w-8 h-8 mt-3  p-2 md:block group-hover:block cursor-pointer"
        >
          <AiOutlineCloseCircle className="w-full h-full fill-current" />
        </button>
      </div>

      {isSearchLoading ? (
        <Stack>
          <Skeleton isLoaded={!isSearchLoading} height="90px" />
          <Skeleton isLoaded={!isSearchLoading} height="90px" />
          <Skeleton isLoaded={!isSearchLoading} height="90px" />
          <Skeleton isLoaded={!isSearchLoading} height="90px" />
          <Skeleton isLoaded={!isSearchLoading} height="90px" />
        </Stack>
      ) : (
        <>
          {userSearchResult.length ? (
            <>
              <h3 className="ml-7 font-bold">Users</h3>
              {userSearchResult.map((userResult) => {
                return (
                  <Flex
                    key={userResult.id}
                    flexDirection={'column'}
                    flex="auto"
                    border="thick"
                    borderColor="gray.800"
                    onClick={() => handleUserClick(userResult)}
                    style={{ cursor: 'pointer' }}
                  >
                    <div className="chat-header px-6 py-4 flex flex-row flex-none justify-between items-center shadow">
                      <div className="flex">
                        <div className="w-12 h-12 mr-4 relative flex flex-shrink-0">
                          <img
                            className="shadow-md rounded-full w-full h-full object-cover"
                            src={userResult?.photo || profilePicture}
                            alt=""
                          />
                        </div>
                        <div className="text-sm">
                          <p className="font-bold">{userResult?.name}</p>
                          <p className="text-gray-300">{userResult?.username}</p>
                        </div>
                      </div>
                    </div>
                  </Flex>
                )
              })}
            </>
          ) : null}

          {searchResult.length ? (
            <>
              <h3 className="ml-6 font-bold">Results</h3>
              {searchResult.map((message) => {
                return (
                  <Flex
                    key={message._id}
                    flexDirection={'column'}
                    flex="auto"
                    border="thick"
                    borderColor="gray.800"
                    onClick={() => handleMessageClick(message)}
                    style={{ cursor: 'pointer' }}
                  >
                    <div className="chat-header px-6 py-4 flex-row flex-none justify-between items-center shadow">
                      <div>
                        <p className="font-bold">{message?.user?.name}</p>
                      </div>
                      <div className="text-sm mt-2 flex justify-between">
                        <p className="font-bold">
                          {userInfo.id === message.senderId ? 'You: ' : null}
                          {message?.text}
                        </p>
                        <p className="text-gray-300">
                          {format(new Date(message?.updatedAt), 'yyyy-MM-dd')}
                        </p>
                      </div>
                    </div>
                  </Flex>
                )
              })}
            </>
          ) : (
            <p className="text-center">No Results Found</p>
          )}
        </>
      )}
    </div>
  )
}

export default ShowSearchMessage
