import React, { useContext, useEffect, useRef, useState } from 'react'
import Peer from 'simple-peer'
import {
  Box,
  HStack,
  IconButton,
  Modal,
  ModalBody,
  ModalContent,
  SimpleGrid,
  Grid,
  GridItem,
  
} from '@chakra-ui/react'
import { MdCallEnd } from 'react-icons/md'
import { IoMdMic, IoMdMicOff } from 'react-icons/io'
import { GlobalContext } from '../..'

const Video = ({ peer }) => {
  const ref = useRef()

  useEffect(() => {
    peer.on('stream', (stream) => {
      if (ref.current) {
        ref.current.srcObject = stream
      }
    })
  }, [peer])

  return <video playsInline autoPlay ref={ref} width={'100%'} height={'100%'} />
}

const createPeer = (userToSignal, callerID, stream, socket) => {
  const peer = new Peer({
    initiator: true,
    trickle: false,
    stream,
  })

  peer.on('signal', (signal) => {
    socket.emit('sending signal', { userToSignal, callerID, signal })
  })

  return peer
}

const addPeer = (incomingSignal, callerID, stream, socket) => {
  const peer = new Peer({
    initiator: false,
    trickle: false,
    stream,
  })

  peer.on('signal', (signal) => {
    socket.emit('returning signal', { signal, callerID })
  })

  peer.signal(incomingSignal)

  return peer
}

const EventCallRoom = ({ roomID, userProfile, attendees, onEnd }) => {
  const [peers, setPeers] = useState([])
  const [muted, setMuted] = useState(false)
  const { socket } = useContext(GlobalContext)
  const userVideo = useRef(null)
  const peersRef = useRef([])

  const handleMute = () => setMuted(!muted)

  const handleEndCall = () => {
    peers.forEach((peer) => peer.destroy())
    setPeers([])
    onEnd()
  }

  const setupSocketListeners = (stream) => {
    socket.emit('join room', roomID)

    socket.on('all users', (users) => {
      const newPeers = users.map((userID) => {
        const peer = createPeer(userID, socket.id, stream, socket)
        peersRef.current.push({ peerID: userID, peer })
        return peer
      })
      setPeers(newPeers)
    })

    socket.on('user joined', (payload) => {
      const peer = addPeer(payload.signal, payload.callerID, stream, socket)
      peersRef.current.push({ peerID: payload.callerID, peer })
      setPeers((prevPeers) => [...prevPeers, peer])
    })

    socket.on('receiving returned signal', (payload) => {
      const item = peersRef.current.find((p) => p.peerID === payload.id)
      item.peer.signal(payload.signal)
    })
  }

  useEffect(() => {
    navigator.mediaDevices.getUserMedia({ video: true, audio: true }).then((stream) => {
      if (userVideo.current) {
        userVideo.current.srcObject = stream
      }

      setupSocketListeners(stream)
    })
    // eslint-disable-next-line
  }, [])

   console.log('peers', peers)
  return (
    <Modal onClose={handleEndCall} size={'full'} isOpen={true}>
      <ModalContent m={0} borderRadius={0} maxWidth={'100vw'} maxHeight={'100vh'}>
        <ModalBody p={0}>
          <Grid
            templateAreas={`"header" "main" "footer"`}
            gridTemplateRows={'50px 1fr 50px'}
            gridTemplateColumns={'1fr'}
            h={'100vh'}
            gap={0}
          >
            <GridItem area={'header'}>
            
            </GridItem>

            <GridItem  area={'main'}>
              <SimpleGrid columns={2} spacing={0}>
                <Box   height="full">
                  <video muted ref={userVideo} playsInline autoPlay />
                </Box>
                {peers.map((peer, index) => (
                  <Box key={index}   height="full">
                    <Video peer={peer} />
                  </Box>
                ))}
              </SimpleGrid>
            </GridItem>

            <GridItem  area={'footer'}>
              <HStack justifyContent={'center'} spacing={4}>
                <IconButton
                  rounded={'full'}
                  bg={'white'}
                  aria-label={'Mute/Unmute'}
                  size={'lg'}
                  icon={muted ? <IoMdMicOff color={'black'} size={26} /> : <IoMdMic color={'black'} size={26} />}
                  onClick={handleMute}
                />
                <IconButton
                  rounded={'full'}
                  bg={'red.500'}
                  aria-label={'End Call'}
                  size={'lg'}
                  icon={<MdCallEnd color={'white'} size={24} />}
                  onClick={handleEndCall}
                />
              </HStack>
            </GridItem>
          </Grid>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
export default EventCallRoom
