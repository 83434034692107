import {
  Box,
  Flex,
  HStack,
  IconButton,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  Text,
  toast,
  useBoolean,
  useOutsideClick,
} from '@chakra-ui/react'
import { Picker } from 'emoji-mart'
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { BsEmojiSmile } from 'react-icons/bs'
import { IoAttachOutline, IoPersonOutline } from 'react-icons/io5'
import { useRouteMatch } from 'react-router'
import { GlobalContext } from '../..'
import {
  createComment,
  getCommentByPost,
  getCommentByPublicPost,
} from '../../services/comments.service'
import { getPost, getPostPublic } from '../../services/post.service'
import Comment from './Comment'
import CreatePost from './CreatePost'
import Post from './Post'

const initialPost = {
  id: null,
  photos: [],
}

const PostPage = () => {
  const [post, setPost] = useState({})
  const [postAvailable, setPostAvailable] = useState(true)
  const [showCreatePostModal, setShowCreatePostModal] = useState(false)
  const { params } = useRouteMatch()
  const { id: postId, userid: userId } = params
  const emojiWindowRef = useRef(null)
  const [comment, setComment] = useState('')
  const [showEmojiWindow, setShowEmojiWindow] = useBoolean()
  const [, setIsLoading] = useState(false)
  const [, setAttachments] = useState([])
  const [getComment, setGetComment] = useState([])
  const { userInfo } = useContext(GlobalContext)

  const handleGetPost = async (postId, userId) => {
    try {
      if (userId) {
        const { data: post } = await getPost(postId)
        setPost(post)
      } else {
        const { data: post } = await getPostPublic(postId)
        setPost(post)
      }
    } catch (error) {
      setPostAvailable(false)
    }
  }

  useEffect(() => {
    if (postId && userInfo.id) handleGetPost(postId, userInfo.id).then()
  }, [postId, userInfo.id])

  useOutsideClick({
    ref: emojiWindowRef,
    handler: () => setShowEmojiWindow.off(),
  })

  const commentOnChange = (e) => {
    setComment(e.target.value)
  }

  const handleCommentOnPost = useCallback(async () => {
    try {
      setIsLoading(true)
      const res = await createComment({
        postId: post.id,
        text: comment,
      })
      if (res.status === 201) {
        try {
          const { data: comments } = await getCommentByPost(postId)
          setGetComment(comments)
          const { data: post } = await getPost(postId)
          setPost(post)
        } catch (error) {
          toast({
            title: 'Oops! Something Went Wrong',
            description: `${error.message}`,
            status: 'error',
            duration: 5000,
            isClosable: true,
          })
        }
        setComment('')
        setAttachments([])
        setIsLoading(false)
      }
    } catch (error) {
      toast({
        title: 'Oops! Something Went Wrong',
        description: `${error.message}`,
        status: 'error',
        duration: 5000,
        isClosable: true,
      })
    }
  }, [comment, post, postId])

  const handleGetComment = async (postId, userId) => {
    try {
      if (userId) {
        const { data: comments } = await getCommentByPost(postId)
        setGetComment(comments)
      } else {
        const { data: comments } = await getCommentByPublicPost(postId)
        setGetComment(comments)
      }
    } catch (error) {
      toast({
        title: 'Oops! Something Went Wrong',
        description: `${error.message}`,
        status: 'error',
        duration: 5000,
        isClosable: true,
      })
    }
  }

  useEffect(() => {
    if (postId && userInfo.id) handleGetComment(postId, userInfo.id).then()
  }, [postId, userInfo.id])

  return (
    <Flex paddingTop={28} alignItems={'stretch'} justifyContent={'center'}>
      {showCreatePostModal && (
        <CreatePost
          onPost={() => {
            setShowCreatePostModal(false)
          }}
          onClose={() => {
            setShowCreatePostModal(false)
          }}
          postData={initialPost}
          repostId={post.id}
        />
      )}
      <Box maxW={'3xl'} w={'full'}>
        <div className="border-solid border-2 rounded">
          {post?.id && post?.user?.username === userId && postAvailable ? (
            <Post
              post={{
                ...post,
                profilePhoto: post?.user?.photo,
                name: post?.user?.name,
                userName: post?.user?.username,
              }}
              onDelete={() => {}}
              onEdit={() => {}}
              onRepost={() => {
                setShowCreatePostModal(true)
              }}
              hideEdit={true}
              getComment={getComment}
            />
          ) : (
            <Text textAlign={'center'}>Post is not available or restricted</Text>
          )}

          {userInfo.id && (
            <Flex alignItems={'stretch'} justifyContent={'center'}>
              <Box maxW={'3xl'} w={'full'}>
                <div className="flex-1 pr-2 pt-2 m-2 border-solid border-2 rounded">
                  <textarea
                    className="pl-2 bg-transparent text-gray-400 font-medium text-base w-full focuc-visible:border-opacity-0"
                    placeholder="Write a comment..."
                    value={comment}
                    onChange={commentOnChange}
                  ></textarea>
                  {comment ? (
                    <div className="flex mr-2">
                      <div className="flex-1 px-2">
                        <div className="flex">
                          <Popover
                            isOpen={showEmojiWindow}
                            onClose={() => {}}
                            closeOnBlur={false}
                            preventOverflow={false}
                            placement={'bottom'}
                            boundary={'scrollParent'}
                            strategy={'fixed'}
                          >
                            <HStack>
                              <IconButton
                                rounded={'full'}
                                variant={'ghost'}
                                aria-label={''}
                                size={'md'}
                                title={'File Attach'}
                                color={'primary.500'}
                                icon={<IoAttachOutline size={32} />}
                              />
                              <PopoverTrigger>
                                <IconButton
                                  rounded={'full'}
                                  variant={'ghost'}
                                  aria-label={''}
                                  size={'md'}
                                  title={'Emoji Picker'}
                                  color={'primary.500'}
                                  icon={<BsEmojiSmile size={24} />}
                                  onClick={() => {
                                    setShowEmojiWindow.toggle()
                                  }}
                                />
                              </PopoverTrigger>
                              <IconButton
                                rounded={'full'}
                                variant={'ghost'}
                                aria-label={''}
                                size={'md'}
                                title={'Tag Connection'}
                                color={'primary.500'}
                                icon={<IoPersonOutline size={24} />}
                              />
                            </HStack>
                            <PopoverContent w={'auto'} p={0}>
                              <PopoverArrow />
                              <PopoverCloseButton />
                              <PopoverBody
                                sx={{
                                  '.emoji-mart-bar': {
                                    display: 'none',
                                  },
                                }}
                              >
                                <Picker
                                  theme={'dark'}
                                  showPreview={false}
                                  onSelect={(emoji) => {
                                    setComment(`${comment}${emoji?.native}`)
                                  }}
                                />
                              </PopoverBody>
                            </PopoverContent>
                          </Popover>
                        </div>
                      </div>

                      <div className="flex pb-2">
                        <div className="flex flex-1 justify-end mr-2">
                          <button
                            className={
                              'flex items-center w-42 z-10 py-2 px-3 bg-purple-600 text-white rounded-md'
                            }
                            onClick={handleCommentOnPost}
                          >
                            Add Comment
                          </button>
                        </div>
                      </div>
                    </div>
                  ) : null}
                </div>
              </Box>
            </Flex>
          )}
          <div>
            {getComment?.map((comment) => (
              <Comment key={comment.id} comment={comment} handleGetComment={handleGetComment} />
            ))}
          </div>
        </div>
      </Box>
    </Flex>
  )
}

export default PostPage
