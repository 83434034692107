import React from 'react'
import { useHistory } from 'react-router-dom'
import { useContext, useState, useEffect } from 'react'
import profilePicture from '../../assets/svgs/avtar-rect.svg'
import { FiMoreVertical } from 'react-icons/fi'
import { GlobalContext } from '../../index'
import { getUnreadMessages, readMessages } from '../../services/conversations.service'
import { IconButton, Menu, MenuButton, MenuItem, MenuList, useToast } from '@chakra-ui/react'

const Thread = ({
  messageThread,
  currentContact,
  contact,
  onReceiverSelected,
  scrollChatToBottom,
  relativeTime,
  onUpdateDeleteDate,
  onDeleteThread,
  setShowThread,
}) => {
  const history = useHistory()
  const { userInfo } = useContext(GlobalContext)
  const [unread, setUnread] = useState(0)
  const toast = useToast()

  useEffect(() => {
    async function fetchData() {
      try {
        const resp = await getUnreadMessages({
          threadId: messageThread?._id,
          userId: userInfo.id,
        })
        if (resp.status === 200) {
          const { unread } = resp?.data
          if (unread) {
            setUnread(unread)
          }
        }
      } catch (error) {
        toast({
          title: 'Oops! Something Went Wrong',
          description: error.message,
          status: 'error',
          duration: 5000,
          isClosable: true,
        })
      }
    }

    if (contact?._id !== currentContact?._id) {
      fetchData()
    }
  }, [messageThread, userInfo, toast, currentContact, contact])

  const OnMarkAsRead = () => {
    try {
      const { senderId, receiverId, lastMessage } = messageThread
      readMessages({
        threadId: messageThread._id,
        messageId: lastMessage.messageId,
        senderId: senderId === userInfo.id ? receiverId : senderId,
      })
      setUnread(0)
    } catch (error) {
      toast({
        title: 'Oops! Something Went Wrong',
        description: error.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      })
    }
  }

  return (
    <div
      className={`flex justify-between mb-2 items-center p-3 hover:bg-gray-800 rounded-lg relative ${
        contact?._id === currentContact?._id ? 'bg-gray-700' : ''
      }`}
      onClick={() => {
        history.replace({})
        localStorage.setItem('thread', JSON.stringify(messageThread))

        setUnread(0)
        onReceiverSelected(messageThread, contact)
        scrollChatToBottom()
      }}
    >
      <div className="w-16 h-16 relative flex flex-shrink-0">
        <img
          className="shadow-md rounded-full w-full h-full object-cover"
          src={contact?.photo || profilePicture}
          alt=""
        />
        <div
          className={`absolute bg-gray-900 p-1 rounded-full bottom-0 right-0 ${
            contact?.isOnline ? '' : 'hidden'
          }`}
        >
          <div className="bg-green-500 rounded-full w-3 h-3" />
        </div>
      </div>
      <div className="flex-auto min-w-0 ml-4 mr-6 group-hover:block">
        <p className="font-bold">{contact?.name}</p>
        <div
          className={`flex justify-between items-center text-sm ${
            messageThread.hasNewMessage ? 'font-bold' : ''
          }`}
        >
          <div className="min-w-0">
            {messageThread?.lastMessage?.type === 1 ? (
              <>
                <p className="truncate">{messageThread?.lastMessage?.text || 'Attachment file'}</p>
              </>
            ) : messageThread?.lastMessage?.receiverIdLastMessage === userInfo.id ? (
              <p className="truncate">Called you</p>
            ) : (
              <p className="truncate">You called</p>
            )}{' '}
          </div>
          <p className="ml-2 whitespace-no-wrap">{relativeTime}</p>
        </div>
      </div>
      <div className={`bg-blue-700 p-2 text-sm rounded-full ${unread > 0 ? '' : 'hidden'}`}>
        {unread}
      </div>
      <div
        className={`bg-blue-700 w-3 h-3 rounded-full flex-shrink-0 group-hover:block ${
          messageThread.hasNewMessage ? '' : 'hidden'
        }`}
      />

      <Menu>
        <MenuButton
          zIndex={20}
          padding={1}
          marginLeft={2}
          as={IconButton}
          aria-label="menu"
          right={0}
          bottom={0}
          rounded={'full'}
          onClick={(e) => e.stopPropagation()}
          icon={<FiMoreVertical color="white" />}
        />
        <MenuList zIndex={9999}>
          <MenuItem
            onClick={(e) => {
              e.stopPropagation()
              OnMarkAsRead()
            }}
            min={6}
            color="white"
          >
            Mark as read
          </MenuItem>
          <MenuItem onClick={() => onDeleteThread(messageThread?._id)} min={6} color="white">
            Remove for everyone
          </MenuItem>
          <MenuItem onClick={() => onUpdateDeleteDate(messageThread?._id)} min={6} color="white">
            Remove for me
          </MenuItem>
        </MenuList>
      </Menu>
    </div>
  )
}

export default Thread
