import { useToast } from '@chakra-ui/react'
import debounce from 'lodash.debounce'
import React, { useEffect } from 'react'
import useState from 'react-usestateref'
import { getSearchMessage } from '../../services/conversations.service'
import { getUserSearch } from '../../services/user.service'

export const isHttpLink = (string) => {
  let url
  try {
    url = new URL(string)
  } catch (_) {
    return false
  }
  return url.protocol === 'http:' || url.protocol === 'https:'
}

export const isPreviewLink = (string = '') => {
  if (!isHttpLink(string))  {
    return null
  }
  const url = new URL(string)
  const internal = (url.host === 'conectar.ch') || url.host === window.location.host
  const pathRoutes = url.pathname.split('/').slice(1)
  const [author, page, id] = pathRoutes
  return internal && page === 'post' && { page, link: { id, origin: url.origin, author } }
}

export const useHandleMessageSearch = () => {
  const toast = useToast()
  const [isSearchLoading, setIsSearchLoading] = useState(false)
  const [userSearchResult, setUserSearchResult] = useState([])
  const [searchResult, setSearchResult] = useState([])
  const [searchText, setSearchText] = useState('')
  const [isSearchTouched, setIsSearchTouched] = useState(false)
  const [isSearch, setIsSearch] = useState(false)
  const [searchDate, onChange] = useState('')

  const handleClearSearch = () => {
    setSearchText('')
    onChange('')
  }

  useEffect(() => {
    if (isSearchTouched && !searchText) {
      handleClearSearch()
      setIsSearch(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText, isSearchTouched])

  const handleMessageSearch = async (searchText, searchDate) => {
    setIsSearchLoading(true)
    try {
      if (searchText || searchDate) {
        const searchResult = await getSearchMessage(searchText, searchDate)
        if (searchResult?.status === 200) {
          setSearchResult(searchResult?.data)
          setIsSearchLoading(false)
        }
        if (searchText) {
          const userResult = await getUserSearch(searchText, true)
          if (userResult?.status === 200) {
            setUserSearchResult(userResult?.data)
            setIsSearchLoading(false)
          }
        }
        setIsSearch(true)
      }
    } catch (error) {
      toast({
        title: 'Oops! Something Went Wrong',
        description: error.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      })
    }
    setIsSearchLoading(false)
  }

  const debouncedMessageSearch = React.useMemo(() => {
    return debounce(handleMessageSearch, 400)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    isSearchLoading,
    setIsSearchLoading,
    userSearchResult,
    setUserSearchResult,
    searchResult,
    setSearchResult,
    searchText,
    setSearchText,
    isSearchTouched,
    setIsSearchTouched,
    isSearch,
    setIsSearch,
    handleMessageSearch,
    debouncedMessageSearch,
    searchDate,
    onChange,
    handleClearSearch,
  }
}
