import React, { useContext, useEffect, useState } from 'react'
import { Box, Button, Flex, Spacer, Tooltip, useDisclosure, useToast } from '@chakra-ui/react'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import { GlobalContext } from '../../..'
import './style.css'
import EventModal from './EventModal'
import { getUserEvents } from '../../../services/event.service'
import { lightFormat } from 'date-fns'
import { generateCallLink } from '../../../utils/calendar-utils'
import EventDetailsModal from './EventDetailsModal'

const Calendar = ({ history }) => {
  const { userInfo: loggedInUser } = useContext(GlobalContext)
  const calendarUser = history?.location?.state?.userDetails || loggedInUser
  const isOwnProfile = calendarUser.id === loggedInUser.id
  const { isOpen, onOpen, onClose } = useDisclosure()
  const toast = useToast()
  const { isOpen: isOpenDetails, onOpen: onOpenDetails, onClose: onCloseDetails } = useDisclosure()
  const [selectedEventId, setSelectedEventId] = useState("")
  const [selectedDate, setSelectedDate] = useState()
  const [parsedEvents, setParsedEvents] = useState([])
  const [eventLink, setEventLink] = useState('')

  const renderEventContent = (eventInfo) => {
    const { event } = eventInfo
    const eventTitle = event.title
    const eventTime = event.allDay
      ? 'All day'
      : `${event.start.toLocaleTimeString('en-US', {
          hour: '2-digit',
          minute: '2-digit',
        })} - ${
          event.end
            ? event.end.toLocaleTimeString('en-US', {
                hour: '2-digit',
                minute: '2-digit',
              })
            : ''
        }`
    const tooltip = eventTitle + ' / ' + eventTime
    return (
      <Tooltip label={tooltip} closeDelay={2000}>
        {event.title.length < 12 ? event.title : `${event.title.substring(0, 13)}...`}
      </Tooltip>
    )
  }

  const handleDateSelect = (selectInfo) => {
    setSelectedDate({ ...selectInfo })
  }

  const fetchEvents = async () => {
    const events = await getUserEvents(calendarUser.id)
    const parsed = events.data?.map((event, index) => {
      const { _id, title, startDateTimeUTC, endDateTimeUTC, isAllDay } = event
      return {
        id: _id,
        title,
        start: lightFormat(new Date(startDateTimeUTC), 'yyyy-MM-dd hh:mm'),
        end: lightFormat(new Date(endDateTimeUTC), 'yyyy-MM-dd hh:mm'),
        allDay: isAllDay,
      }
    })
    setParsedEvents(parsed)
  }

  useEffect(() => {
    if (selectedDate) {
      setEventLink(generateCallLink())
      onOpen()
    }
    // eslint-disable-next-line
  }, [selectedDate])

  useEffect(() => {
    if (!isOpen) setSelectedDate()
  }, [isOpen])

  useEffect(() => {
    try {
      fetchEvents()
    } catch (error) {
      toast({
        title: `${error}`,
        description: `${error.message}`,
        status: 'error',
        duration: 5000,
        isClosable: true,
      })
    }
    // eslint-disable-next-line
  }, [, loggedInUser])

  return (
    <>
      <Flex alignItems="center" justifyContent="center" p="0 100px">
        <Box width="100%" height="100%" p="100px">
          <Flex mb="20px">
            <h1 className='className="text-gray-100 text-3xl'>
              {isOwnProfile ? 'My Calendar' : `${calendarUser.name}'s Calendar`}
            </h1>
            <Spacer />
            <Button onClick={() => history.goBack()}>Go Back</Button>
          </Flex>
          {loggedInUser && (
            <CalendarWrapper
              renderEventContent={renderEventContent}
              handleDateSelect={handleDateSelect}
              parsedEvents={parsedEvents}
              setSelectedEventId={setSelectedEventId}
              onOpenDetails={onOpenDetails}
            />
          )}
        </Box>
      </Flex>
      <EventModal
        isOpen={isOpen}
        link={eventLink}
        onClose={onClose}
        selectedDate={selectedDate}
        fetchEvents={fetchEvents}
      />
      <EventDetailsModal isOpen={isOpenDetails} eventId={selectedEventId} onClose={onCloseDetails} />
    </>
  )
}

export default Calendar

const CalendarWrapper = ({
  renderEventContent,
  handleDateSelect,
  parsedEvents,
  setSelectedEventId,
  onOpenDetails,
}) => {
  return (
    <Box>
      {parsedEvents.length > 0 && (
        <FullCalendar
          plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
          headerToolbar={{
            left: 'prev,next today',
            center: 'title',
            right: 'dayGridMonth,timeGridWeek,timeGridDay',
          }}
          initialView="dayGridMonth"
          editable={true}
          selectable={true}
          selectMirror={true}
          dayMaxEvents={true}
          eventContent={renderEventContent}
          select={handleDateSelect}
          initialEvents={parsedEvents}
          eventBorderColor="#2c3e50"
          eventClick={(eventData) => {
            setSelectedEventId(eventData?.event?.id)
            onOpenDetails()
          }}
        />
      )}
      {/* This is required because otherwise the calendar won't show when the user has not events yet and we need to display the calendar to create events. This is a problem of the library itself not updating the state correctly  */}
      {parsedEvents.length === 0 && (
        <FullCalendar
          plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
          headerToolbar={{
            left: 'prev,next today',
            center: 'title',
            right: 'dayGridMonth,timeGridWeek,timeGridDay',
          }}
          initialView="dayGridMonth"
          editable={true}
          selectable={true}
          selectMirror={true}
          dayMaxEvents={true}
          eventContent={renderEventContent}
          select={handleDateSelect}
          initialEvents={[]} // This is required because otherwise the calendar won't show when the user has not events yet
          eventBorderColor="#2c3e50"
        />
      )}
    </Box>
  )
}
