/* eslint-disable react-hooks/exhaustive-deps */

import { IoMdMic, IoMdMicOff } from 'react-icons/io'
import { formatDistanceToNow, parseISO } from 'date-fns'
import { useParams, useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { BsCameraVideoOffFill, BsFillCameraVideoFill } from 'react-icons/bs'
import {
  Button,
  Flex,
  Heading,
  HStack,
  IconButton,
  Link,
  Text,
  useClipboard,
  VStack,
} from '@chakra-ui/react'

import { GlobalContext } from '../..'
import { getEventAttendeesProfiles, getEventByLinkId } from '../../services/event.service'
import { BiCopy } from 'react-icons/bi'
import EventCallRoom from './EventCallRoom'

const CallLobby = () => {
  const [event, setEvent] = useState(null)
  const [muted, setMuted] = useState(false)
  const [videoOn, setVideoOn] = useState(true)
  const [, setUserVideoStream] = useState(null)
  const [attendees, setAttendees] = useState([])
  const [showEventCallWindow, setShowEventCallWindow] = useState(false)
  const [haveAccess, setHaveAccess] = useState(false)

  const history = useHistory()
  const userVideo = useRef(null)
  const { id: linkId } = useParams()
  const { userInfo } = useContext(GlobalContext)
  const { onCopy } = useClipboard(window.location.href)

  const handleMute = () => setMuted((previousMuteValue) => !previousMuteValue)

  const handleVideo = () =>
    setVideoOn((wasVideoOn) => {
      if (!wasVideoOn) {
        loadVideo()
      }
      setVideoOn(!wasVideoOn)
    })

  const loadVideo = () => {
    navigator.mediaDevices
      .getUserMedia({ video: true, audio: true })
      .then((stream) => {
        setUserVideoStream(stream)
        if (userVideo.current) {
          userVideo.current.srcObject = stream
        }
      })
      .catch((error) => console.error(error))
  }

  const HandleCallAccess = () => {
    if (!userInfo || !event) {
      return false
    }

    const isEventCreator = userInfo.id === event.createdBy
    const isAttendee = attendees.some((attendee) => attendee.id === userInfo.id)

    const hasAccess = isEventCreator || isAttendee
    setHaveAccess(hasAccess)

    return hasAccess
  }

  useEffect(() => {
    const loadEventInformation = async () => {
      const {
        data: { event },
      } = await getEventByLinkId(linkId)
      const {
        data: { attendees },
      } = await getEventAttendeesProfiles(event.id)

      setEvent(event)
      setAttendees(attendees)
    }

    loadEventInformation()
    loadVideo()

    console.log('access', haveAccess)
    return () => {
      if (userVideo.current && userVideo.current.srcObject) {
        const tracks = userVideo.current.srcObject.getTracks()
        tracks.forEach((track) => track.stop())
      }
    }
  }, [linkId])

  useEffect(() => {
    HandleCallAccess()
  }, [event, attendees, userInfo])

  const HandleEventCallWindow = () => {
    setShowEventCallWindow(true)
  }

  return (
    <>
      {haveAccess && (
        <Flex alignItems="center" justifyContent="center" width="100%" minHeight="100%">
          {showEventCallWindow && (
            <EventCallRoom
              onEnd={() => setShowEventCallWindow(false)}
              userProfile={userInfo}
              attendees={attendees}
              roomID={event.id}
            />
          )}
          <VStack spacing={5}>
            {videoOn ? (
              <video
                ref={userVideo}
                autoPlay
                muted
                style={{
                  height: '100%',
                  width: '100%',
                }}
                playsInline
              />
            ) : (
              <Heading fontSize="2xl">Camera is Off</Heading>
            )}
            <HStack bg={'gray.800'} p={5} justifyContent={'center'} spacing={4}>
              <IconButton
                rounded={'full'}
                bg={'white'}
                aria-label={''}
                size={'lg'}
                title={'Mute/Unmute'}
                icon={
                  muted ? (
                    <IoMdMicOff color={'black'} size={26} />
                  ) : (
                    <IoMdMic color={'black'} size={26} />
                  )
                }
                onClick={handleMute}
              />
              <IconButton
                rounded={'full'}
                bg={'red.500'}
                aria-label={''}
                size={'lg'}
                title={'End Call'}
                icon={
                  videoOn ? (
                    <BsFillCameraVideoFill color={'white'} size={24} />
                  ) : (
                    <BsCameraVideoOffFill color={'white'} size={24} />
                  )
                }
                onClick={handleVideo}
              />
            </HStack>
          </VStack>
          {event && (
            <VStack m="4rem" spacing={10} align="center" height="100%">
              <Heading fontSize="4xl">{event.title}</Heading>
              <Text fontSize="xl">
                The clock is ticking, be ready to join the call in{' '}
                {formatDistanceToNow(parseISO(event.startDateTimeUTC), { addSuffix: true })}
              </Text>
              <ul className="flex mt-6">
                <div className="flex mr-6">
                  <div>
                    <img
                      className="inline-block h-12 w-12 rounded-full"
                      src={userInfo.photo}
                      alt=""
                    />
                    <p className="ml-3 text-sm leading-6 font-medium text-white">You</p>
                  </div>
                </div>
                {attendees.map((attendee, index) => {
                  return (
                    <div className="flex mr-6" key={index}>
                      <div>
                        <img
                          className="inline-block h-12 w-12 rounded-full"
                          src={attendee.photo}
                          alt=""
                        />
                        <p className="ml-1 text-sm leading-6 font-medium text-white">
                          {attendee.name.split(' ')[0]}
                        </p>
                      </div>
                    </div>
                  )
                })}
              </ul>
              <HStack>
                <Button onClick={HandleEventCallWindow} cursor="pointer" bgColor="green.500">
                  Join the Call Now
                </Button>
                <Button cursor="pointer" bgColor="red.500" onClick={() => history.goBack()}>
                  Leave Lobby
                </Button>
              </HStack>
              <VStack>
                <Heading fontSize="xl">Joining Info</Heading>
                <Text>Share this joining info with others you want in the meeting</Text>
                <HStack>
                  <Link color={'gray.400'}>
                    {window.location.href.replace(/^(https?:\/\/)?(www\.)?/, '')}
                  </Link>
                  <IconButton
                    aria-label="Copy to clipboard"
                    icon={<BiCopy />}
                    onClick={onCopy}
                    size="sm"
                  />
                </HStack>
              </VStack>
            </VStack>
          )}
        </Flex>
      )}

      {!haveAccess && (
        <Flex alignItems="center" justifyContent="center" width="100%" minHeight="100%">
          <VStack>
            <Heading fontSize="4xl">You don't have access to this call</Heading>
            <Button cursor="pointer" bgColor="red.500" onClick={() => history.goBack()}>
              Go Back
            </Button>
          </VStack>
        </Flex>
      )}
    </>
  )
}

export default CallLobby
