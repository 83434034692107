import {
  Box,
  Center,
  Checkbox,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  VStack,
} from '@chakra-ui/react'
import classNames from 'classnames'
import { format } from 'date-fns'
import React, { useState } from 'react'

import { BiCheckDouble } from 'react-icons/bi'
import { FiMoreVertical } from 'react-icons/fi'
import { ImFilesEmpty } from 'react-icons/im'
import profilePicture from '../../assets/svgs/avtar-rect.svg'
import PostLinkPreview from './components/PostLinkPreview'
import { isHttpLink, isPreviewLink } from './utils'

function MessageItem({
  message,
  userInfo,
  currentContact,
  scrollMessageId,
  scrollRef,
  generateFileName,
  setShowForwardModal,
  setMultipleMessageSelect,
  handleFileDownload,
  onDeleteMessage,
  multipleSelect,
  handleMultiMessageSelect,
}) {
  const [showTimestamp, setShowTimestamp] = useState(false)

  const isMyMessage = message.senderId === userInfo.id
  let messageText = message.text
  const postPreview = isPreviewLink(messageText)
  const httpLink = isHttpLink(messageText)
  if (message.type === 2) {
    messageText = isMyMessage
      ? `You called ${currentContact?.name || ''}`
      : `${currentContact?.name || ''} called you`
  }
  return (
    <>
      <div
        key={message._id}
        className={classNames('flex flex-row items-center mb-4 justify-start', {
          'justify-end': isMyMessage,
        })}
        ref={message._id === scrollMessageId ? scrollRef : null}
      >
        {!isMyMessage && (
          <div className="w-8 h-8 flex flex-shrink-0 mr-4">
            <img
              className="shadow-md rounded-full w-full h-full object-cover"
              src={currentContact?.photo || profilePicture}
              alt=""
            />
            <div
              className={classNames(
                'absolute bg-green-500 border-2 border-gray-900 rounded-full bottom-0 right-0 w-3 h-3',
                {
                  hidden: !currentContact.isOnline,
                }
              )}
            />
          </div>
        )}
        {message.type === 4 ? (
          <Box>
            <audio style={{}} src={message.mediaUrl} controls />
          </Box>
        ) : (
          <div className="messageThreads text-sm text-gray-700 grid grid-flow-row gap-2">
            {message.forwarded && (
              <div>
                <p className="text-gray-500">Forwarded message</p>
              </div>
            )}
            <div
              className="flex items-center group cursor-pointer"
              onClick={() => setShowTimestamp((show) => !show)}
            >
              <div>
                <div>
                  {messageText && (
                    <>
                      {postPreview && <PostLinkPreview {...postPreview} />}
                      <p
                        className={classNames(
                          'px-5 py-3 mt-4 rounded-t-full max-w-xs lg:max-w-md text-gray-200 bg-gray-800 rounded-r-full',
                          {
                            'bg-blue-700 rounded-l-full': isMyMessage,
                            italic: message.type === 2,
                            hidden: postPreview,
                          }
                        )}
                      >
                        {message._id === scrollMessageId && scrollMessageId}
                        {httpLink ? (
                          <a
                            className="underline"
                            href={messageText}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {messageText}
                          </a>
                        ) : (
                          messageText
                        )}
                      </p>
                      {isMyMessage && message?.status === 'read' && (
                        <BiCheckDouble className="w-5 h-6 float-right  text-blue-500" />
                      )}
                    </>
                  )}
                </div>

                <div className="mt-2">
                  {message.attachments.map((attachment, index) => {
                    let fileUrl = attachment.url
                    return (
                      <div key={index}>
                        {attachment.type === 'photo' ? (
                          <div className="w-80 h-full">
                            <img src={fileUrl} alt="Img" />
                            {isMyMessage && message?.status === 'read' && (
                              <BiCheckDouble className="w-5 h-6 float-right  text-blue-500" />
                            )}
                          </div>
                        ) : attachment.type === 'video' ? (
                          <div className="w-80 h-full">
                            <video src={fileUrl} controls />
                            {isMyMessage && message?.status === 'read' && (
                              <BiCheckDouble className="w-5 h-6 float-right  text-blue-500" />
                            )}
                          </div>
                        ) : attachment.type === 'pdf' ? (
                          <div className="w-80 h-full">
                            <embed
                              title={attachment.name}
                              src={fileUrl}
                              type="application/pdf"
                              width="100%"
                              height="100%"
                              controls
                            ></embed>
                            {isMyMessage && message?.status === 'read' && (
                              <BiCheckDouble className="w-5 h-6 float-right  text-blue-500" />
                            )}
                          </div>
                        ) : attachment.type === 'file' ? (
                          <div>
                            <Center h={'full'}>
                              <VStack>
                                <Icon as={ImFilesEmpty} boxSize={20} />
                                <Text color="white">{generateFileName(attachment.name, 10)}</Text>
                              </VStack>
                            </Center>
                            {isMyMessage && message?.status === 'read' && (
                              <BiCheckDouble className="w-5 h-6 float-right  text-blue-500" />
                            )}
                          </div>
                        ) : (
                          <p>File not supported</p>
                        )}
                      </div>
                    )
                  })}
                </div>
              </div>

              {isMyMessage && (
                <>
                  <Menu>
                    <MenuButton
                      zIndex={20}
                      padding={2}
                      marginLeft={2}
                      as={IconButton}
                      aria-label="menu"
                      right={0}
                      bottom={0}
                      rounded={'full'}
                      onClick={(e) => e.stopPropagation()}
                      icon={<FiMoreVertical color="white" />}
                    />
                    <MenuList zIndex={9999}>
                      <MenuItem
                        min={6}
                        color="white"
                        onClick={() => {
                          setShowForwardModal(true)
                          setMultipleMessageSelect([message._id])
                        }}
                      >
                        Forward
                      </MenuItem>
                      {!messageText && (
                        <MenuItem
                          min={6}
                          color="white"
                          onClick={() => handleFileDownload(message.attachments)}
                        >
                          Download
                        </MenuItem>
                      )}
                      <MenuItem min={6} color="white" onClick={() => onDeleteMessage(message._id)}>
                        Delete
                      </MenuItem>
                    </MenuList>
                  </Menu>
                  {multipleSelect && (
                    <Checkbox
                      value={message._id}
                      onChange={(e) => handleMultiMessageSelect(e)}
                      ml={2}
                      size="sm"
                      colorScheme="green"
                    />
                  )}
                </>
              )}
            </div>
          </div>
        )}
      </div>
      <div
        className={classNames('flex pt-2 flex-row text-gray-500 text-xs -mt-5  ', {
          'justify-start ml-12': !isMyMessage,
          'justify-end mr-12': isMyMessage,
        })}
      >
        {showTimestamp && <p>{format(new Date(message.createdAt), 'hh:mm aa yyyy-dd-MM')}</p>}
      </div>
    </>
  )
}

export default MessageItem
