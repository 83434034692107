import {
  Avatar,
  Box,
  Center,
  CloseButton,
  HStack,
  IconButton,
  Modal,
  ModalBody,
  ModalContent,
  VStack,
  Text,
  Icon,
  Flex,
  Heading,
  Spinner,
  useToast,
} from '@chakra-ui/react'
import React, { useContext, useEffect, useRef } from 'react'
import { MdCallEnd } from 'react-icons/md'
import { BsFillChatFill } from 'react-icons/bs'
import { BiBlock } from 'react-icons/bi'
import Peer from 'simple-peer'
import useState from 'react-usestateref'
import { GlobalContext } from '../..'
import {
  acceptCall,
  callUser,
  createMessage,
  endCall,
  getMessages,
} from '../../services/conversations.service'
import { IoSendSharp } from 'react-icons/io5'
import { IoMdMic, IoMdMicOff } from 'react-icons/io'
import { MdScreenShare, MdStopScreenShare } from 'react-icons/md'
import scrollIntoView from 'scroll-into-view-if-needed'
import profilePicture from '../../assets/svgs/avtar-rect.svg'
import { uuidv4 } from '../../services/util.service'

const CALL_STATUS = {
  CALLING: 'calling',
  CONNECTING: 'connecting',
  CONNECTED: 'connected',
  UNREACHABLE: 'unreachable',
  PERMISSION_ERROR: 'no-permission',
}

const SCROLL_TARGET = 'chat-windows-scroll-target-' + uuidv4()

const CallWindow = ({
  onEnd,
  userProfile,
  isOutGoingCall = false,
  setLastVideoCallId,
  handleAfterNewMessage,
  callerSignal = '',
  message = {},
  isVideoCall = true,
  setIsVideoCall = () => {},
  currentThreadId,
}) => {
  const [myVideoStream, setMyVideoStream, myVideoStreamRef] = useState(null)
  const [, setPartnerStream, partnerStreamRef] = useState(null)
  const [callMessage, setCallMessage, callMessageRef] = useState(message)
  const [isLoading, setIsLoading] = useState(false)
  const [hasNewMessage, setHasNewMessage] = useState(false)
  const [showChat, setShowChat, showChatRef] = useState(false)
  const [callStatus, setCallStatus] = useState(CALL_STATUS.CONNECTING)
  const [muted, setMuted] = useState(false)
  const [userMessages, setUserMessages] = useState([])
  const [typedMessage, setTypedMessage] = useState('')
  const [countDownTime, setCountDownTime] = useState(10)
  const [isSharingScreen, setIsSharingScreen] = useState(false)
  const [screenStream, setScreenStream] = useState(null)
  const [myPeer, setMyPeer] = useState(null)
  const { userInfo: myInfo, socket } = useContext(GlobalContext)
  const toast = useToast()

  const myVideo = useRef(null)
  const partnerVideo = useRef(null)

  const shareScreen = async () => {
    setIsSharingScreen(true)
    const captureStream = await navigator.mediaDevices.getDisplayMedia({})
    const captureStreamTrack = captureStream?.getTracks().find((track) => track.kind === 'video')
    setScreenStream(captureStream)

    setMyVideoStream(captureStream)
    if (myVideo.current) myVideo.current.srcObject = captureStream

    if (myPeer) {
      myPeer.streams[0].getVideoTracks()[0].stop()
      myPeer.replaceTrack(
        myPeer.streams[0].getVideoTracks()[0],
        captureStreamTrack,
        myPeer.streams[0]
      )
    }
  }

  const stopShareScreen = async () => {
    setIsSharingScreen(false)
    const videoStream = await navigator.mediaDevices.getUserMedia({
      video: isVideoCall,
      audio: true,
    })
    const videoStreamTrack = videoStream?.getTracks().find((track) => track.kind === 'video')

    setMyVideoStream(videoStream)
    if (myVideo.current) myVideo.current.srcObject = videoStream

    if (myPeer) {
      if (screenStream) {
        screenStream.getTracks().forEach((track) => track.stop())
        setScreenStream(null)
      }

      myPeer.streams[0].getVideoTracks()[0].stop()
      myPeer.replaceTrack(
        myPeer.streams[0].getVideoTracks()[0],
        videoStreamTrack,
        myPeer.streams[0]
      )
    }
  }

  useEffect(() => {
    navigator.mediaDevices
      .getUserMedia({ video: isVideoCall, audio: true })
      .then((stream) => {
        setMyVideoStream(stream)
        if (myVideo.current) {
          myVideo.current.srcObject = stream
        }

        if (isOutGoingCall) {
          onCallPeer(stream, userProfile)
        } else {
          onAcceptCall(stream, userProfile, callMessage)
          onGetMessages(callMessage.threadId || currentThreadId)
        }
      })
      .catch((_) => {
        setCallStatus(CALL_STATUS.PERMISSION_ERROR)
      })

    socket.on('endCall', () => {
      handleVideoAndCallEnd()
    })

    socket.on('rejectedCall', () => {
      handleVideoAndCallEnd()
    })

    socket?.on('message', (data) => handleOnMessage(data))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    socket?.on('no_user', (message) => {
      handleCallEnd()
      toast({
        title: `We're Sorry`,
        description: message,
        status: 'warning',
        duration: 5000,
        isClosable: true,
      })
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket, toast])

  useEffect(() => {
    if (callStatus === CALL_STATUS.CONNECTED) {
      if (partnerVideo?.current) {
        partnerVideo.current.srcObject = partnerStreamRef.current
      }
    }
    if (callStatus === CALL_STATUS.PERMISSION_ERROR) {
      const interval = setInterval(() => {
        if (countDownTime > 0) {
          setCountDownTime((prevValue) => prevValue - 1)
        } else {
          clearInterval(interval)
        }
      }, 1000)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callStatus])

  useEffect(() => {
    if (countDownTime === 0) {
      setIsVideoCall(false)
    }
  }, [countDownTime, setIsVideoCall])

  const handleVideoAndCallEnd = () => {
    if (myVideoStreamRef?.current) {
      let tracks = myVideoStreamRef.current.getTracks()
      for (const track of tracks) {
        track.stop()
      }
    }
    if (myVideo?.current) {
      myVideo.current.srcObject = null
    }
    onEnd()
  }

  const onCallPeer = (stream, userProfile) => {
    setLastVideoCallId(userProfile?._id)
    setIsLoading(true)
    const peer = new Peer({
      initiator: true,
      trickle: false,
      config: {
        iceServers: [
          {
            urls: 'stun:openrelay.metered.ca:80',
          },
          {
            urls: 'turn:openrelay.metered.ca:80',
            username: 'openrelayproject',
            credential: 'openrelayproject',
          },
          {
            urls: 'turn:openrelay.metered.ca:443',
            username: 'openrelayproject',
            credential: 'openrelayproject',
          },
          {
            urls: 'turn:openrelay.metered.ca:443?transport=tcp',
            username: 'openrelayproject',
            credential: 'openrelayproject',
          },
        ],
      },
      stream,
    })

    peer.on('signal', async (data) => {
      const reqObject = {
        message: {
          text: '',
          receiverId: userProfile._id,
          type: 2,
          connectionId: socket?.id,
          signalData: data,
        },
        isVideoCall,
      }

      try {
        const {
          data: { message },
        } = await callUser(reqObject)

        setCallMessage(message)
        await onGetMessages(message.threadId || currentThreadId)
      } catch (error) {
        if (error) {
          toast({
            title: 'Oops! Something Went Wrong',
            description: error.message,
            status: 'error',
            duration: 5000,
            isClosable: true,
          })
        }
        onEnd()
      }
    })

    peer.on('stream', (stream) => {
      setPartnerStream(stream)
      if (partnerVideo?.current) {
        partnerVideo.current.srcObject = stream
      }
    })

    socket.on('callAccepted', (signal) => {
      setCallStatus(CALL_STATUS.CONNECTED)
      peer.signal(signal)
    })

    setMyPeer(peer)
  }

  const onAcceptCall = (stream, userProfile, callMessage) => {
    const peer = new Peer({
      initiator: false,
      trickle: false,
      stream,
    })

    peer.on('signal', async (data) => {
      const reqObject = {
        message: {
          text: '',
          receiverId: userProfile._id,
          type: 2,
          connectionId: socket?.id,
          messageId: callMessage.id,
          signalData: data,
        },
      }

      await acceptCall(reqObject)
    })

    peer.on('stream', (stream) => {
      setCallStatus(CALL_STATUS.CONNECTED)
      setPartnerStream(stream)
    })

    peer.signal(callerSignal)

    setMyPeer(peer)
  }

  const handleCallEnd = async () => {
    const reqObject = {
      message: {
        text: '',
        receiverId: userProfile._id,
        type: 2,
        messageId: callMessageRef.current.id,
        connectionId: socket?.id,
      },
    }
    await endCall(reqObject)
    handleVideoAndCallEnd()
  }

  const handleMute = () => {
    let streamAudio = myVideoStreamRef.current.getAudioTracks()[0]
    streamAudio.enabled = muted
    setMuted(!muted)
  }

  const handleOnMessage = (data) => {
    if (!!!showChatRef?.current) {
      setHasNewMessage(true)
    }
    const { message, thread } = data
    setUserMessages((messageThreads) => {
      return [...messageThreads, message]
    })
    handleAfterNewMessage(message, thread, message.senderId)
    scrollChatToBottom()
  }

  const onGetMessages = async (threadId) => {
    try {
      setIsLoading(true)
      let { data: messages } = await getMessages(threadId)

      setUserMessages(messages || [])
      setIsLoading(false)
      scrollChatToBottom()
    } catch (error) {
      setIsLoading(false)
    }
  }

  const onMessageCreate = async () => {
    if (typedMessage) {
      const reqObject = {
        message: {
          text: typedMessage,
          receiverId: userProfile._id,
          type: 1,
        },
      }
      setTypedMessage('')
      let {
        data: { message },
      } = await createMessage(reqObject)
      setUserMessages([...userMessages, message])
      scrollChatToBottom()
    }
  }

  const scrollChatToBottom = () => {
    let currentDOMItem = document.getElementById(SCROLL_TARGET)
    if (currentDOMItem) {
      setTimeout(() => {
        scrollIntoView(currentDOMItem, {
          behavior: 'smooth',
          scrollMode: 'if-needed',
        })
      }, 200)
    }
  }

  return (
    <Modal onClose={handleCallEnd} size={'full'} isOpen={true}>
      <ModalContent>
        <ModalBody p={0} h={'100vh'} flex={'none'}>
          <Box h={'full'} boxSizing={'border-box'}>
            <HStack alignItems={'stretch'} spacing={0} h={'full'}>
              <VStack flex={5} alignItems={'stretch'} spacing={0} h={'full'}>
                <VStack alignItems={'stretch'} spacing={0} h={'full'} position={'relative'}>
                  <HStack
                    p={5}
                    justifyContent={'center'}
                    spacing={4}
                    pos={'absolute'}
                    right={2}
                    top={2}
                    zIndex={20}
                  >
                    <Box pos={'relative'}>
                      <IconButton
                        rounded={'full'}
                        variant={'ghost'}
                        aria-label={''}
                        title={'Chat'}
                        color={showChat ? 'primary.400' : 'white'}
                        icon={<BsFillChatFill size={20} />}
                        onClick={() => {
                          setHasNewMessage(false)
                          setShowChat(!showChat)
                          onGetMessages(currentThreadId)
                          !showChat && scrollChatToBottom()
                        }}
                      />
                      <div
                        className={`absolute bg-red-500 border-2 border-white rounded-full bottom-2 right-2 w-3 h-3 ${
                          hasNewMessage ? '' : 'hidden'
                        }`}
                      />
                    </Box>
                    <CloseButton size="lg" rounded={'full'} onClick={handleCallEnd} />
                  </HStack>
                  <Box
                    position={'absolute'}
                    bottom={4}
                    left={4}
                    bg={'blackAlpha.300'}
                    rounded={'md'}
                    py={2}
                    px={4}
                    zIndex={20}
                  >
                    <Text fontSize="xs">{`${userProfile.name || 'User'}`}</Text>
                  </Box>
                  {isVideoCall && (
                    <Box
                      position={'absolute'}
                      bottom={4}
                      right={4}
                      bg={'gray.900'}
                      rounded={'md'}
                      h={152}
                      w={260}
                      zIndex={20}
                      overflow={'hidden'}
                      shadow={'lg'}
                    >
                      {myVideoStream && (
                        <Box w={'full'} h={'full'} position={'relative'}>
                          <video
                            ref={myVideo}
                            autoPlay
                            muted
                            // loop
                            style={{
                              height: '100%',
                              width: '100%',
                              objectFit: 'contain',
                              position: 'absolute',
                              left: 0,
                              top: 0,
                            }}
                            playsInline
                          />
                          {muted && (
                            <Icon
                              color={'white'}
                              size={18}
                              as={IoMdMicOff}
                              pos={'absolute'}
                              left={4}
                              bottom={4}
                            />
                          )}
                        </Box>
                      )}
                    </Box>
                  )}
                  {callStatus === CALL_STATUS.PERMISSION_ERROR && isVideoCall ? (
                    <Center h={'full'}>
                      <VStack>
                        <Icon color={'white'} boxSize={32} as={BiBlock} />
                        <Heading fontSize="2xl" fontWeight={'semibold'}>
                          {`You Haven't Allowed Your Camera`}
                        </Heading>
                        <Text textAlign={'center'}>
                          Allow your camera from permission settings so others on the call can see
                          and hear you.
                          <br />
                          Otherwise, the call will switch to a voice call in {countDownTime}...
                        </Text>
                      </VStack>
                    </Center>
                  ) : callStatus !== CALL_STATUS.CONNECTED ? (
                    <Center h={'full'}>
                      <VStack>
                        <Avatar
                          size="2xl"
                          name={userProfile.name || ''}
                          src={userProfile.photo || ''}
                        />
                        <Text fontSize="2xl" fontWeight={'semibold'}>
                          {`Calling ${userProfile.name || 'User'}`}...
                        </Text>
                      </VStack>
                    </Center>
                  ) : (
                    <Box h={'100%'}>
                      <video
                        ref={partnerVideo}
                        autoPlay
                        // muted
                        // loop
                        style={{
                          height: '100%',
                          width: '100%',
                          objectFit: 'contain',
                          position: 'absolute',
                          left: 0,
                          top: 0,
                        }}
                        playsInline
                        id="helooo"
                      />
                    </Box>
                  )}
                </VStack>
                <HStack bg={'gray.800'} p={5} justifyContent={'center'} spacing={4}>
                  {isVideoCall && (
                    <IconButton
                      rounded={'full'}
                      bg={'white'}
                      aria-label={''}
                      size={'lg'}
                      title={'Share Screen'}
                      icon={
                        isSharingScreen ? (
                          <MdStopScreenShare color={'black'} size={26} />
                        ) : (
                          <MdScreenShare color={'black'} size={26} />
                        )
                      }
                      onClick={() => (isSharingScreen ? stopShareScreen() : shareScreen())}
                    />
                  )}
                  <IconButton
                    rounded={'full'}
                    bg={'white'}
                    aria-label={''}
                    size={'lg'}
                    title={'Mute/Unmute'}
                    icon={
                      muted ? (
                        <IoMdMicOff color={'black'} size={26} />
                      ) : (
                        <IoMdMic color={'black'} size={26} />
                      )
                    }
                    onClick={handleMute}
                  />
                  <IconButton
                    rounded={'full'}
                    bg={'red.500'}
                    aria-label={''}
                    size={'lg'}
                    title={'End Call'}
                    icon={<MdCallEnd color={'white'} size={24} />}
                    onClick={handleCallEnd}
                  />
                </HStack>
              </VStack>

              <Flex
                display={showChat ? 'flex' : 'none'}
                flex={2}
                h={'full'}
                direction={'column'}
                bg={'gray.900'}
                pt={5}
              >
                {isLoading ? (
                  <Center h={'full'}>
                    <Spinner size="xl" />
                  </Center>
                ) : (
                  <>
                    <div className="flex-col-reverse justify-start chat-body p-4 flex-1 overflow-y-scroll">
                      {userMessages.map((message, i) => {
                        let isMyMessage = message.senderId === myInfo.id
                        let messageText = message.text
                        if (message.type === 2) {
                          messageText = isMyMessage
                            ? `You called ${userProfile?.name || ''}`
                            : `${userProfile?.name || ''} called you`
                        }
                        return (
                          <div
                            key={i}
                            className={`flex flex-row items-center mb-4 ${
                              isMyMessage ? 'justify-end' : 'justify-start'
                            }`}
                          >
                            {!isMyMessage && (
                              <div className="w-8 h-8 relative flex flex-shrink-0 mr-4">
                                <img
                                  className="shadow-md rounded-full w-full h-full object-cover"
                                  src={userProfile?.photo || profilePicture}
                                  alt=""
                                />
                                <div
                                  className={`absolute bg-green-500 border-2 border-gray-900 rounded-full bottom-0 right-0 w-3 h-3 ${
                                    userProfile?.isOnline ? '' : 'hidden'
                                  }`}
                                />
                              </div>
                            )}
                            <div className="messageThreads text-sm text-gray-700 grid grid-flow-row gap-2">
                              <div className="flex items-center group">
                                <p
                                  className={`px-6 py-3 rounded-t-full max-w-xs lg:max-w-md text-gray-200 ${
                                    isMyMessage
                                      ? 'bg-blue-700 rounded-l-full'
                                      : 'bg-gray-800 rounded-r-full'
                                  } ${message.type === 2 ? 'italic' : ''}`}
                                >
                                  {messageText}
                                </p>
                              </div>
                            </div>
                          </div>
                        )
                      })}
                      <div id={SCROLL_TARGET} />
                    </div>
                    <div className="chat-footer flex-none">
                      <div className="flex flex-row items-center p-4">
                        <div className="relative flex-grow">
                          <label>
                            <input
                              className="rounded-full py-2 pl-3 pr-10 w-full border border-gray-800 focus:border-gray-700 bg-gray-800 focus:bg-gray-900 focus:outline-none text-gray-200 focus:shadow-md transition duration-300 ease-in"
                              type="text"
                              value={typedMessage}
                              onChange={({ target }) => {
                                setTypedMessage(target.value)
                              }}
                              onKeyPress={(event) => {
                                if (event.key === 'Enter') {
                                  onMessageCreate()
                                }
                              }}
                              placeholder="Aa"
                            />
                            <button
                              type="button"
                              className="absolute top-0 right-0 mt-2 mr-3 flex flex-shrink-0 focus:outline-none text-blue-600 hover:text-blue-700 w-6 h-6"
                            >
                              <svg
                                viewBox="0 0 20 20"
                                className="w-full h-full fill-current hidden"
                              >
                                <path d="M10 20a10 10 0 1 1 0-20 10 10 0 0 1 0 20zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zM6.5 9a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm7 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm2.16 3a6 6 0 0 1-11.32 0h11.32z" />
                              </svg>
                            </button>
                          </label>
                        </div>
                        <IconButton
                          cursor={'pointer'}
                          ml={2}
                          variant="unstyled"
                          aria-label="Send"
                          as={IoSendSharp}
                          w={8}
                          h={8}
                          color="primary.500"
                          onClick={onMessageCreate}
                        />
                      </div>
                    </div>
                  </>
                )}
              </Flex>
            </HStack>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default CallWindow
